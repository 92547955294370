var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{attrs:{"overflow-scroll":"","max-height-if-outside-viewport":""}},[_c('ul',{staticClass:"context__menu"},[(
        _vm.$hasPermission(
          'database.table.create_row',
          _vm.table,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){;[_vm.$emit('create-row'), _vm.hide()]}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-plus"}),_vm._v("\n        "+_vm._s(_vm.$t('kanbanViewStackContext.createCard'))+"\n      ")])]):_vm._e(),_vm._v(" "),(
        _vm.option !== null &&
        _vm.$hasPermission(
          'database.table.field.update',
          _vm.singleSelectField,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{ref:"updateContextLink",staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.$refs.updateContext.toggle(_vm.$refs.updateContextLink)}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-edit-pencil"}),_vm._v("\n        "+_vm._s(_vm.$t('kanbanViewStackContext.editStack'))+"\n      ")]),_vm._v(" "),_c('KanbanViewUpdateStackContext',{ref:"updateContext",attrs:{"option":_vm.option,"fields":_vm.fields,"store-prefix":_vm.storePrefix},on:{"saved":function($event){return _vm.hide()}}})],1):_vm._e(),_vm._v(" "),(
        _vm.option !== null &&
        _vm.$hasPermission(
          'database.table.field.update',
          _vm.singleSelectField,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item context__menu-item--with-separator"},[_c('a',{staticClass:"context__menu-item-link context__menu-item-link--delete",on:{"click":function($event){return _vm.$refs.deleteModal.show()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-bin"}),_vm._v("\n        "+_vm._s(_vm.$t('kanbanViewStackContext.deleteStack'))+"\n      ")])]):_vm._e()]),_vm._v(" "),(_vm.option !== null)?_c('Modal',{ref:"deleteModal"},[_c('h2',{staticClass:"box__title"},[_vm._v("\n      "+_vm._s(_vm.$t('kanbanViewStackContext.delete', { name: _vm.option.value }))+"\n    ")]),_vm._v(" "),_c('Error',{attrs:{"error":_vm.error}}),_vm._v(" "),_c('div',[_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('kanbanViewStackContext.deleteDescription', {
            name: _vm.option.value,
          }))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"align-right"},[_c('Button',{attrs:{"type":"danger","size":"large","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.deleteStack()}}},[_vm._v("\n            "+_vm._s(_vm.$t('kanbanViewStackContext.delete', { name: _vm.option.value }))+"\n          ")])],1)])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }