var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"tree__sub",class:{ active: _vm.table._.selected }},[_c('a',{staticClass:"tree__sub-link",class:{ 'tree__sub-link--empty': _vm.table.name === '' },attrs:{"title":_vm.table.name,"href":_vm.resolveTableHref(_vm.database, _vm.table)},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){$event.preventDefault();return _vm.selectTable(_vm.database, _vm.table)}}},[_c('Editable',{ref:"rename",attrs:{"value":_vm.table.name},on:{"change":function($event){return _vm.renameTable(_vm.database, _vm.table, $event)}}})],1),_vm._v(" "),(_vm.showOptions)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(!_vm.database._.loading),expression:"!database._.loading"}],staticClass:"tree__options",on:{"click":function($event){return _vm.$refs.context.toggle($event.currentTarget, 'bottom', 'right', 0)},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"baserow-icon-more-vertical"})]):_vm._e(),_vm._v(" "),_c('Context',{ref:"context",attrs:{"overflow-scroll":"","max-height-if-outside-viewport":""}},[_c('div',{staticClass:"context__menu-title"},[_vm._v(_vm._s(_vm.table.name)+" ("+_vm._s(_vm.table.id)+")")]),_vm._v(" "),_c('ul',{staticClass:"context__menu"},[_vm._l((_vm.additionalContextComponents),function(component,index){return _c('li',{key:index,staticClass:"context__menu-item",on:{"click":function($event){return _vm.$refs.context.hide()}}},[_c(component,{tag:"component",attrs:{"table":_vm.table,"database":_vm.database}})],1)}),_vm._v(" "),(
          _vm.$hasPermission(
            'database.table.run_export',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.exportTable()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-share-ios"}),_vm._v("\n          "+_vm._s(_vm.$t('sidebarItem.exportTable'))+"\n        ")])]):_vm._e(),_vm._v(" "),(
          _vm.$hasPermission(
            'database.table.create_webhook',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.openWebhookModal()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-globe"}),_vm._v("\n          "+_vm._s(_vm.$t('sidebarItem.webhooks'))+"\n        ")])]):_vm._e(),_vm._v(" "),(
          _vm.$hasPermission(
            'database.table.update',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.enableRename()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-edit-pencil"}),_vm._v("\n          "+_vm._s(_vm.$t('action.rename'))+"\n        ")])]):_vm._e(),_vm._v(" "),(
          _vm.$hasPermission(
            'database.table.duplicate',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('SidebarDuplicateTableContextItem',{attrs:{"database":_vm.database,"table":_vm.table,"disabled":_vm.deleteLoading},on:{"click":function($event){return _vm.$refs.context.hide()}}})],1):_vm._e(),_vm._v(" "),(
          _vm.$hasPermission(
            'database.table.delete',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link context__menu-item-link--delete",class:{ 'context__menu-item-link--loading': _vm.deleteLoading },on:{"click":function($event){return _vm.deleteTable()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-bin"}),_vm._v("\n          "+_vm._s(_vm.$t('action.delete'))+"\n        ")])]):_vm._e()],2),_vm._v(" "),_c('ExportTableModal',{ref:"exportTableModal",attrs:{"database":_vm.database,"table":_vm.table}}),_vm._v(" "),_c('WebhookModal',{ref:"webhookModal",attrs:{"database":_vm.database,"table":_vm.table}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }