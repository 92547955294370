var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{class:{ 'context--loading-overlay': _vm.state === 'loading' },attrs:{"max-height-if-outside-viewport":""},on:{"shown":_vm.shown}},[_c('div',{staticClass:"data-source-context__container"},[(_vm.state === 'loaded')?_c('div',{directives:[{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll"}],staticClass:"data-source-context__content data-source-context__content--scrollable"},[(_vm.dataSources.length > 0)?_c('div',{staticClass:"data-source-context__forms"},_vm._l((_vm.dataSources),function(dataSource){return _c('ReadOnlyForm',{key:dataSource.id,attrs:{"read-only":!_vm.$hasPermission(
              'builder.page.data_source.update',
              dataSource,
              _vm.workspace.id
            )}},[_c('DataSourceForm',{ref:`dataSourceForm_${dataSource.id}`,refInFor:true,attrs:{"id":dataSource.id,"builder":_vm.builder,"data-source":dataSource,"page":_vm.page,"default-values":dataSource,"integrations":_vm.integrations,"loading":_vm.dataSourcesLoading.includes(dataSource.id)},on:{"delete":function($event){return _vm.deleteDataSource(dataSource)},"values-changed":function($event){return _vm.updateDataSource(dataSource, $event)}}})],1)}),1):[_c('div',{staticClass:"data-source-context__none"},[_c('div',{staticClass:"data-source-context__none-title"},[_vm._v("\n            "+_vm._s(_vm.$t('dataSourceContext.noDataSourceTitle'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"data-source-context__none-description"},[_vm._v("\n            "+_vm._s(_vm.$t('dataSourceContext.noDataSourceMessage'))+"\n          ")])])]],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"context__footer"},[(
          _vm.$hasPermission(
            'builder.page.create_data_source',
            _vm.page,
            _vm.workspace.id
          )
        )?_c('ButtonText',{attrs:{"icon":"iconoir-plus","loading":_vm.creationInProgress},on:{"click":function($event){return _vm.createDataSource()}}},[_vm._v("\n        "+_vm._s(_vm.$t('dataSourceContext.addDataSource'))+"\n      ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }