var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h3',[_vm._v(_vm._s(_vm.$t('workspaceInviteForm.invitationFormTitle')))]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-7"},[_c('FormGroup',{attrs:{"small-label":"","error":_vm.fieldHasErrors('email')},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v("\n          "+_vm._s(_vm.$t('workspaceInviteForm.errorInvalidEmail'))+"\n        ")]},proxy:true}])},[_c('FormInput',{ref:"email",attrs:{"error":_vm.fieldHasErrors('email')},on:{"blur":function($event){return _vm.$v.values.email.$touch()}},model:{value:(_vm.values.email),callback:function ($$v) {_vm.$set(_vm.values, "email", $$v)},expression:"values.email"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"col col-5"},[_c('FormGroup',[_c('div',{staticClass:"workspace-invite-form__role-selector"},[_vm._t("roleSelectorLabel"),_vm._v(" "),_c('Dropdown',{staticClass:"workspace-invite-form__role-selector-dropdown",attrs:{"show-search":false,"fixed-items":"","small":""},model:{value:(_vm.values.permissions),callback:function ($$v) {_vm.$set(_vm.values, "permissions", $$v)},expression:"values.permissions"}},_vm._l((_vm.roles),function(role,index){return _c('DropdownItem',{key:index,ref:'role' + role.uid,refInFor:true,attrs:{"name":role.name,"value":role.uid,"disabled":role.isDeactivated,"description":role.description},on:{"click":function($event){return _vm.clickOnDeactivatedItem($event)}}},[_vm._v("\n              "+_vm._s(role.name)+"\n              "),(role.showIsBillable && role.isBillable)?_c('Badge',{attrs:{"color":"cyan","size":"small","bold":""}},[_vm._v(_vm._s(_vm.$t('common.billable'))+"\n              ")]):(
                  role.showIsBillable &&
                  !role.isBillable &&
                  _vm.atLeastOneBillableRole
                )?_c('Badge',{staticClass:"margin-left-1",attrs:{"color":"yellow","size":"small","bold":""}},[_vm._v(_vm._s(_vm.$t('common.free'))+"\n              ")]):_vm._e(),_vm._v(" "),(role.isDeactivated)?_c('i',{staticClass:"iconoir-lock"}):_vm._e(),_vm._v(" "),_c(_vm.deactivatedClickModal(role),{ref:'deactivatedClickModal-' + role.uid,refInFor:true,tag:"component",attrs:{"v-if":_vm.deactivatedClickModal(role),"name":_vm.$t('workspaceInviteForm.additionalRoles'),"workspace":_vm.workspace}})],1)}),1)],2)])],1),_vm._v(" "),_c('div',{staticClass:"col col-12 margin-top-2 margin-bottom-2"},[_c('FormGroup',{attrs:{"error":_vm.fieldHasErrors('message')},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v("\n          "+_vm._s(_vm.$t('workspaceInviteForm.errorTooLongMessage', {
              amount: _vm.messageMaxLength,
            }))+"\n        ")]},proxy:true}])},[_c('FormInput',{ref:"message",attrs:{"error":_vm.fieldHasErrors('message'),"placeholder":_vm.$t('workspaceInviteForm.optionalMessagePlaceholder')},model:{value:(_vm.values.message),callback:function ($$v) {_vm.$set(_vm.values, "message", $$v)},expression:"values.message"}})],1)],1),_vm._v(" "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }